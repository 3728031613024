<template>
  <b-overlay
    variant="transparent"
    no-wrap
  />
</template>

<script>

export default {
  name: 'LaboratoryBindServiceRedirectPage',
  components: {},
  data() {
    return {};
  },
  async created() {
    this.$store.commit(this.$types.PARTNER_SERVICES_SET, null);
    await this.$store.commit(this.$types.BIND_SERVICES_OFFSET_SET, 0);

    const { data: { data } } = await this.$store.dispatch(this.$types.GET_LABORATORY_PARTNER_SERVICES,
      {
        limit: 1, offSet: 0, isMerged: false, save: false, isMappable: true,
      });
    const dataSimilarService = await this.$store.dispatch(this.$types.LABORATORY_PARTNER_SERVICE_GET_SIMILAR_SERVICE,
      data[0].id);

    const currentPartnerService = { ...data[0], optionsSimilarServices: dataSimilarService.data.data };
    this.$store.commit(this.$types.CURRENT_PARTNER_SERVICE_SET, currentPartnerService);

    const startServiceId = currentPartnerService.id;
    await this.$router.push(`/laboratory-bind-service/${startServiceId}`);
  },
};
</script>

<style scoped>

</style>
